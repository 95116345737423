.new-gallery-wrapper {
    width: 100%;
    height: 500px;
    /* background: yellow; */
    overflow: hidden;
    position: relative;
}

/* .new-gallery-wrapper .slick-slide {
    height: 100%;
} */

.image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 500px;
    width: 100%;
    box-sizing: border-box;
    overflow: hidden;
}

.new-gallery-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.gallery-buttons-wrapper {
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
    flex-direction: row-reverse;
    background: rgba(0, 0, 0, 0.5);
    padding: 0px;
}

.new-gallery-button {
    margin-left: 0px;
    border: none;
    margin: 0;
    padding: 12px 15px 8px 15px;
    background-color: black;
}

@media (max-width:500px) {

    .image-container {

        height: 240px;

    }

    .new-gallery-wrapper {
        width: 100%;
        height: 500px;
        overflow: hidden;
        position: relative;

    }

}