.tags {
    font-family: "lato";
    font-weight: 200;
    color: #F7C789;
    border: 1px solid #F7C789;

    padding: 5px 15px 5px;
    cursor: pointer;
    background-color: transparent;
    font-size: 18px;
    margin-right: 5px;
}

.tags.active {
    font-family: "lato";
    font-weight: 300;
    border: 1px solid #F7C789;
    color: #2D393B;

    background-color: #F7C789;

}

.faq-wrapper {
    margin-top: 100px;
    width: 100%;
}


h5 {
    font-family: "lato";
    font-weight: 200;
    font-size: 20px;
    color: #F7C789;
    margin-block: 20px;
    text-transform: none;

}



@media (max-width: 800px) {
    .faq-wrapper {
        margin-top: 80px;

        width: 100%;
    }

    .tags {

        font-size: 16px;
        font-weight: 300;
    }

    .tags.active {
        font-weight: 400;


    }

    h5 {
        font-family: "lato";
        font-weight: 300;
        font-size: 16px;

    }







}