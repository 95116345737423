.menu-wrapper {
    margin-top: 140px;
    width: 100%;
}

.food-image-container {
    display: flex;
    margin-top: 0px;
    padding-top: 10px;
    border-top: 1px solid #bcb39f;

}

.left-box-image {
    align-items: center;
    padding: 10px 20px 10px 0;
}

.menu-header {
    width: 100%;
    height: 150px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background-image: url(/public/img/menu-banner.png);
    background-size: cover;
}

.headline-tab-container {
    background-color: #f9f3e7;
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    padding-top: 30px;
}



@media (max-width: 800px) {
    .faq-wrapper {
        margin-top: 80px;

        width: 100%;
    }

    h5 {
        font-family: "lato";
        font-size: 17px;
        margin-block: 20px;
        line-height: 1.1;
        padding-right: 10px;
    }







}