.deliverButtonWrapper {
    width: 100px;
    position: fixed;
    right: 0;
    z-index: 40;
}

.deliver-button {

    background-color: #D60F0F;
    text-align: center;
    cursor: pointer;
    padding-block: 10px;
}

.reserve-button {

    background-color: #D60F0F;
    margin-top: -13px;
    cursor: pointer;
    padding-block: 10px;
    margin-top: 2px;



}

@media (max-width: 500px) {

    .deliverButtonWrapper {
        width: 100%;
        position: fixed;
        bottom: 0;
        z-index: 40;
        display: flex;
        justify-content: space-between;
        padding: 5px;
        align-items: center;
        background-color: rgba(36, 16, 16, 0.4);
        /* Rot mit 50% Transparenz */


    }

    .deliver-button {
        background-color: #D60F0F;
        position: relative;
        left: 10px;
        width: 48%;
        text-align: center;
        cursor: pointer;
        padding-block: 0px;
        border-radius: 5px;

    }

    .reserve-button {
        background-color: #D60F0F;
        width: 48%;
        margin-top: 0px;
        cursor: pointer;
        padding-block: 0px;
        margin-top: 0px;
        bottom: 0;
        border-radius: 5px;





    }



}