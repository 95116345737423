.delivery-wrapper {
    display: flex;
    width: 100%;
    background: #3E4C51;

    margin-top: 100px;
}

.box-with-text {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 30px;
    padding-block: 100px;

}

.smartphoneBox {
    width: 50%;
    background: url(/public/img/smartphone.png) no-repeat center top;
    background-size: 60%;
    margin-top: 30px;
}

@media (max-width: 500px) {
    .delivery-wrapper {
        flex-direction: column;
        width: 100vw;
        height: 600px;
        position: relative;
        left: 50%;
        right: 50%;
        margin-left: -50vw;
        margin-right: -50vw;
        margin-top: 50px;
        padding: 0;
    }

    .box-with-text {
        width: 100%;
        margin: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
        padding: 0;
        margin-bottom: 0;
    }

    .delivery-text {
        width: 80%;
        margin: 0 auto;
    }

    .smartphoneBox {
        /* background: url(/public/img/smartphone.png) no-repeat center top; */
        background-size: 60%;
        margin-top: 20px;
        width: 100%;
        height: 100%;
    }


    .delivery-text h4,
    .delivery-text h3,
    .delivery-text p {
        text-align: center;
    }

    .button-container {
        display: flex;
        justify-content: center;
    }
}