.wrapper {
    width: 100%;
    height: 500px;
    display: flex;
    justify-content: space-between;
    margin: auto;
    margin-top: 15px;
}

.boxText {
    width: 50%;
    display: flex;
    justify-content: baseline;
    align-items: center;
    padding-right: 10px;


}

.box-wrapper {
    width: 49.5%;


}

.text {
    max-width: 300px;
    margin-left: 0;
}

.boxImage {
    width: 50%;
    height: 100%;
    background: url(/public/img/smartphone.png) no-repeat center center;
    background-size: contain;


}

@media (max-Width: 700px) {
    .wrapper {
        display: block;
        height: 100%;
        margin-top: 230px;
    }

    .box-wrapper {
        width: 100%;
    }





}