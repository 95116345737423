.navigation {
    margin-top: 50px;
    padding-block: 0px;
    align-items: center;
    position: relative;
}

.burgerMenu {
    position: fixed;
    top: 10px;
    right: 10px;
    z-index: 1001;
    cursor: pointer;
}

.mobile-menu {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 100vh;
    width: 100%;
    background-color: #2D393B;
    z-index: 1000;
}

.mobile-menu ul {
    padding: 0;
    list-style: none;
    position: relative;
    padding-top: 80px;
}

.mobile-menu li {
    font-size: 30px;
    padding: 20px 0;
    display: block;
}

a {
    cursor: pointer;
}

a:hover {
    color: #D60F0F;
}

.nav-desktop {
    display: block;
    margin-top: 30px;
}

.burgerMenu {
    display: none;
    align-items: center;
}

li {
    font-family: "lato";
    font-weight: bold;
    list-style-type: none;
    display: inline-block;
    text-align: center;
    margin: auto;
    line-height: 0.5;
    font-size: 20px;
    padding-left: 20px;
}

@media (max-width: 600px) {
    .nav-desktop {
        display: none;
    }

    .burgerMenu {
        display: block;
        margin-top: 10px;
        background-color: #2D393B;
        padding: 5px 7px 0 7px;
        border-radius: 5px;
    }

    .mobile-menu {
        top: 0;
        right: 0;
        height: 100vh;
        width: 100%;
    }
}