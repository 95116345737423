.galleryWrapper {
    position: relative;
    width: 100vw;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    margin-top: 50px;
    background: #3E4C51;
    padding-top: 20px;
    overflow: hidden;
}

.galleryHeadline {
    max-width: 1200px;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 20px;
}

.cardsWrapper {
    max-width: 1200px;
    margin: auto;
    margin-top: 0px;
    /* background-color: cyan; */
    overflow: visible;
    position: relative;
}

.slick-list {
    overflow: visible;
    margin-right: -100px;
}

.galleryBottomWrapper {
    margin: auto;
    width: 100%;
    /* background-color: yellow; */
}

.sliderButtonWrapper {
    z-index: 30;
    display: flex;
    justify-content: flex-end;
    /* background-color: pink; */
    position: relative;
    margin-right: 0px;
}

.slider-button {
    background-color: black;
    border: none;
    height: 60px;
    width: 60px;
}



.card {
    height: 420px;
    width: 97%;
    background: #5F6E73;
    box-sizing: border-box;
}

@media (max-width: 500px) {
    .galleryHeadline {
        margin: auto;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding-inline: 10px;
        overflow: hidden;
    }

    .cardsWrapper {
        width: 100%;
    }

    .slick-list {
        overflow: visible;
        padding-left: 20px;
        margin-left: 0px;
        margin-right: -240px;
    }

    .galleryBottomWrapper {
        margin: auto;
        width: 100%;
    }

    .sliderButtonWrapper {
        width: 90%;
        margin: auto;
        position: relative;
    }

    .card {
        height: 420px;
        width: 97%;
        min-width: 280px;
        margin: 0 20px;

    }
}