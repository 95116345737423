body {
    margin: 0;
}

.kontakt-container {
    display: flex;
    flex-direction: row;
    width: 100vw;
    height: 500px;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    margin-top: 100px;
}

.kontakt-iframe,
.kontakt-info {
    width: 50%;

}

.kontakt-info {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #3E4C51;
    padding-top: 20px;

}

.kontakt-info-inner {
    width: 80%;
    text-align: center;
    margin-left: 20px;

}

/* .kontakt-info-inner h4,
.kontakt-info-inner h3,
.kontakt-info-inner p,
.kontakt-info-inner button {
    text-align: left;
} */



@media (max-width: 700px) {
    .kontakt-container {
        flex-direction: column;
        height: auto;
        /* Anpassung der Höhe für Spaltenlayout */
    }

    .kontakt-iframe {
        width: 100%;
        height: 400px;
    }

    .kontakt-info {
        height: 300px;
        width: 100%;
        justify-content: flex-start;
        align-items: flex-start;

    }
}