body {
    margin: 0;
    padding: 0px;
    background-color: #2D393B;
}

header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    z-index: 1000;
    /* background: red; */

}

main {
    max-width: 1200px;
    margin: 0px auto 0;
    padding: 0 10px 0 10px;
    padding: 0;
}

.logo-container {
    width: 205px;
    height: 130px;
    border: 1px solid #2D393B;
    overflow: hidden;
    float: left;
    margin-top: -10px;
    margin-bottom: 20px;
    margin-left: -20px;

}

.logo-container img {
    width: 100%;
    object-fit: cover;
}


h1 {
    font-family: "lato";
    /* color: #FFC77D; */
    color: white;
    font-size: 90px;
    font-weight: 200;
    text-align: left;
    margin-top: 0;
    margin-left: -5px;
    margin-bottom: 0px;
    line-height: 0.9;
    text-transform: uppercase;
    text-shadow: 0px 15px 15px rgba(0, 5, 0, 3.5);
}

h2 {
    line-height: 0.5;
}

h3 {
    font-family: "lato";
    color: #FFC77D;

    font-weight: 200;
    font-size: 34px;
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: left;
    line-height: 1;
    text-transform: uppercase;
}

h4 {
    font-family: "lato", Helvetica, sans-serif;
    font-weight: 400;
    font-size: 20px;
    margin-top: 10px;
    margin-bottom: -5px;
    text-align: left;
    color: #FFC77D;
    text-transform: uppercase;

}

h5 {
    font-family: "lato", Helvetica, sans-serif;
    text-transform: uppercase;


}


p {
    font-size: 16px;
    font-weight: 200;
    text-align: left;
    font-family: "lato", Helvetica, sans-serif;
    line-height: 1.2;
    color: antiquewhite;
}


button {
    font-family: "lato";
    font-size: 16px;
    font-weight: 400;
    margin: 20px 0;
    padding: 10px 30px 10px;
    border: 1px solid #FFC77D;
    background: none;
    color: #FFC77D;
    display: block;
    cursor: pointer;
}

button:hover {

    background: #131A1B;
    border: 1px solid #131A1B;
}

a {
    text-decoration: none;
    color: #FFC77D;
    font-weight: 200;
    text-transform: uppercase;
    font-size: 18px;
}

a:hover {
    color: #FFC77D !important;
}

a:visited {
    color: #FFC77D;
    /* Gleiche Farbe wie der normale Link */
    text-decoration: none;
    /* Gleiche Textdekoration wie der normale Link */
}

.slider-button {
    border-radius: 0;
    padding: 10px 10px 0px 10px;
    font-size: 16px;
}

.opener {
    width: 100%;
    height: 700px;
    background: url(/public/img/falafel_factory_start.jpg);
}

.tags {
    font-family: "lato";
    color: white;
    padding: 5px 10px 2px;
    border: none;
    cursor: pointer;
    background-color: transparent;
    border: 1px solid black;
    margin-right: 10px;
    white-space: nowrap;

}

.tags.active {
    font-family: "lato";
    border: 1px solid #D60F0F;
    color: white;
    background-color: #D60F0F;

}

.tagsWrapper {
    display: flex;
    overflow: hidden;

}

.tags-container {
    display: flex;
    padding-left: 0px;

}

footer {
    background-color: black;
    width: 100vw;
    height: 110px;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
}

@media (max-width: 1300px) {

    main {
        width: 95%;


    }




}

@media (max-width: 500px) {


    main {
        width: 95%;
    }

    h1 {
        font-size: 70px;
        margin-bottom: 0px;
        font-weight: 300;

    }

    p {

        width: 100%;
        font-weight: 300;


    }



    h2 {
        font-size: 30px;
        font-weight: 300;

    }



    h3 {
        font-size: 25px;
        font-weight: 300;

    }


    h4 {

        font-size: 20px;
        font-weight: bolder;



    }

    h5 {
        font-family: "lato", Helvetica, sans-serif;
        font-size: 17px;
        margin-block: 25px;
        line-height: 1.1;
        padding-right: 10px;
        font-weight: 300;
    }



    .logo-container {
        width: 140px;
        height: 100px;
        margin: -10px 0 0px;
    }




    button {
        font-size: 18px;
        margin: 20px 0;
        display: block;
        cursor: pointer;
    }

    .text {
        margin-top: 0px;

    }

    .boxText {
        height: 400px;
    }

    .tagsWrapper {
        display: flex;
        overflow-x: auto;
        white-space: nowrap;
        width: 100%;
        width: 100vw;
        position: relative;
        left: 50%;
        right: 50%;
        margin-left: -50vw;
        margin-right: -50vw;
        scrollbar-width: none;
        /* Firefox */
        -ms-overflow-style: none;
        /* IE and Edge */
    }

    .tags-container {
        display: flex;
        padding-left: 10px;

    }

    .tagsWrapper::-webkit-scrollbar {
        display: none;
    }

    /*
    .tags {
        font-family: "lato";
        font-weight: 300;
        color: black;
        padding: 5px 10px 2px;
        border: none;
        cursor: pointer;
        background-color: transparent;
        border: 1px solid black;
        margin-right: 10px;
        flex: 0 0 auto;
    }

    .tags.active {
        font-family: "lato";
        font-weight: 300;

        border: 1px solid #D60F0F;
        color: white;
        background-color: #D60F0F;
    } */


}