.heroWrapper {
    width: 100%;
    height: 700px;
    margin-top: 0px;
    background-color: red;
}

.heroImage {
    background: url(/public/img/falafel-falactory-start.png);
    background-position: 55% 0%;
    height: 100%;

}

.hero-text-box {
    position: absolute;
    margin-top: 100px;
    margin-left: 100px;
    width: 500px;
    padding: 20px;
}


.stoerer {
    background-color: black;
    border-radius: 90px;
    padding: 25px 20px 15px 20px;
    margin-right: 200px;
    margin-top: 200px;
    height: 120px;
    width: 120px;
    float: right;
    transform: rotate(-15deg);
    transform-origin: center;
}

.show-mobile {
    display: none;
}



@media (max-Width: 500px) {

    .heroWrapper {
        position: relative;
        width: 100vw;
        left: 50%;
        right: 50%;
        margin-left: -50vw;
        margin-right: -50vw;

        margin-top: 0px;
        z-index: 0;

    }

    .heroImage {
        background: url(/public/img/hero-1-mobile.png);
        background-size: cover;
        background-position: 50% 20%;
        height: 100%;
    }


    .hero-text-box {
        width: 80%;
        position: absolute;
        margin-top: 40px;
        margin-left: 10px;
    }


    .stoerer {

        padding: 15px 15px 15px 15px;
        height: 110px;
        width: 110px;
        float: right;
        margin-top: 320px;
        margin-right: 20px;
    }

    .show-text-desktop {

        display: none;
    }

    .show-button-desktop {

        display: none;
    }

    .show-mobile {
        display: block;
        padding-inline: 10px;
        margin-top: 30px;
    }

    .showMoreOpener {

        display: none;
    }





}