.brunch-wrapper {
    width: 100%;
    display: flex;
    margin-top: 100px;
    overflow: hidden;
    /* background-color: cadetblue; */


}

.image-gallery-container {
    width: 50%;
    height: 500px;
    background-size: cover;
    /* background-color: pink; */
    overflow: hidden;


}

.brunch-text-box {
    width: 50%;
    display: flex;
    justify-content: right;
    align-items: center;
    background: none;

}

.brunch-stoerer {
    padding: 10px;
    background-color: #D60F0F;
    border-radius: 60px;
    width: 90px;
    height: 90px;
    float: left;
    margin-left: 10%;
    margin-top: 20%;
    margin-bottom: 10%;

}

@media (max-width:500px) {

    .brunch-wrapper {
        display: flex;
        flex-direction: column;
        height: 750px;

        width: 100vw;
        height: 730px;
        position: relative;
        left: 50%;
        right: 50%;
        margin-left: -50vw;
        margin-right: -50vw;



    }

    .image-gallery-container {
        width: 100%;
        height: 400px;
        background-size: cover;

    }

    .brunch-text-box {
        margin-top: 0px;
        width: 100%;
        display: flex;
        align-items: center;
        /* background-color: #F3D7AF; */
        padding-top: 10px;


    }

    .brunch-stoerer {
        float: left;
        margin-left: 10%;
        margin-top: 10%;
        margin-bottom: 10%;

    }

    .slick-dots {
        bottom: -35px;

    }




}