.partyservice-wrapper {
    height: 500px;
    display: flex;
    margin-top: 50px;
    margin-bottom: 50px;
    border-radius: 0;
    width: 100vw;
    height: 500px;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    margin-top: 100px;
}

.image-box {
    width: 50%;
    background: url(/public/img/falafel-brot.png) no-repeat;
    background-size: cover;

}

.right-box {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #3E4C51;
}

.text-wrapper {
    width: 80%;
    padding: 20px;
    text-align: center;
}

@media (max-width:500px) {

    .partyservice-wrapper {
        flex-direction: column;
        height: auto;
        margin-top: 50px;

    }



    .image-box {
        width: 100%;
        height: 250px;
        background: url(/public/img/falafel-brot.png) no-repeat center center;
        background-size: cover;
        margin-top: 0;
    }


    .right-box {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }


}